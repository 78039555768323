

@import url('https://fonts.googleapis.com/css?family=Rubik:400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

$primary:   #2f55d4;
$success:   #34D1BF;
$info:      #57B8FF;
$warning:   #FBB13C;
$danger:    #FE6847;
$purple:    #9261c6;
$pink:      #ff7aa3;
$white:     #ffffff;
$dark:      #343a40;
$light:     #f8fafe;
$muted:     #a0a9b1;

// stylelint-disable
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #efefef;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$colors: (
    "primary": $primary,
    "success": $success,
    "info":    $info,
    "warning": $warning,
    "danger":  $danger,
    "dark":    $dark,
    "muted":   $muted,
    "purple":  $purple,
    "pink":    $pink,
    "white":   $white,
    "light":   $light
);

$body-color:  #000000;//  #a0a9b1;

$footer-bg:   #0f1b42;

$font-size-base : .9rem;

$box-shadow:   0px 8px 35px 0px  rgba($gray-700, .05);


$font-family-primary:   'Ubuntu', 'Montserrat', 'Rubik', sans-serif;

// Secondary font
$font-family-secondary: 'Ubuntu', 'Montserrat', 'Rubik', sans-serif;

$font-weight-light:    300;
$font-weight-regular:  400;
$font-weight-medium:   500;
$font-weight-semibold: 600;
$font-weight-bold:     700;

.btn-register {
   color: $white;
   background: rgba($success, 0.4);
   border-color: $success;
   box-shadow: none;

   &:hover{
       color: $dark;
       box-shadow: 0px 16px 31px -16px $success;
       background: darken($success, 4%);
       border-color: darken($success, 4%);
   }   
}

